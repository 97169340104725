<template>
  <div class="home">
    <!-- <i-nav-bar :iTitle="title"></i-nav-bar> -->
    <div class="header_title">
      <!-- <div class="header_title_l">
        <van-image
          class="l_img"
          width="16px"
          height="16px"
          :src="require('./../assets/images/icon_share.png')"
        />
        <span class="txt mgl10" v-if="!isOpenShare">分享</span>
        <span class="txt mgl10" v-else @click="toShare">分享</span>
      </div> -->
      <div class="header_title_r">
        <van-image
          v-if="!isLogin"
          @click="onLogin"
          width="80px"
          height="25px"
          :src="require('./../assets/images/icon_im_login.png')"
        />
        <div class="userInfo" v-else>
          <span class="user_phone">你好 {{ hiddenPhone(phoneNum) }}</span>
          <span class="user_exit" @click="onExit">退出</span>
        </div>
      </div>
    </div>
    <div class="header">
      <van-image
        class="l_img"
        width="100%"
        height="22px"
        :src="require('./../assets/images/icon_header1.png')"
      />
    </div>
    <!-- 爱豆来电 -->
    <div
      class="idol_main"
      :style="`padding-bottom: ${isShowBarrage ? '19px' : '60px'};`"
    >
      <div class="idol_box">
        <van-image
          class="l_img"
          width="100%"
          fit="contain"
          :src="require('./../assets/images/back_idol_call3.png')"
        />
        <div class="barrage" v-if="!isShowBarrage">
          <div class="open_barrage"></div>
        </div>
        <div
          class="open_barrage_btn"
          v-if="!isShowBarrage"
          @click="openBarrage"
        >
          <div>
            <van-image
              class="l_img"
              width="14px"
              fit="contain"
              :src="require('./../assets/images/icon_close.png')"
            ></van-image>
          </div>
          <span class="mgl10">开启弹幕机</span>
        </div>
      </div>
      <van-image
        class="icon_decoration5"
        width="40px"
        height="42px"
        fit="contain"
        :src="require('./../assets/images/login/icon_decoration5.png')"
      />
    </div>

    <!-- 弹幕机 -->
    <div class="barrage_machine" v-if="isShowBarrage">
      <div class="machine_box">
        <van-image
          class="l_img"
          width="100%"
          fit="contain"
          :src="require('./../assets/images/back_barrage_machine.png')"
        />
        <div class="barrage_content_box">
          <Barrage
            :isShow="barrageIsShow"
            :l-barrage-list="barrageList"
            :loop="barrageLoop"
          >
          </Barrage>
        </div>
        <div class="barrage" v-if="isShowBarrage">
          <div class="open_barrage"></div>
        </div>
        <div
          class="close_barrage_btn"
          v-if="isShowBarrage"
          @click="openBarrage"
        >
          <div>
            <van-image
              class="l_img"
              width="14px"
              height="14px"
              :src="require('./../assets/images/icon_close.png')"
            ></van-image>
          </div>
          <span class="mgl10">关闭弹幕机</span>
        </div>
      </div>
      <van-image
        class="icon_decoration1"
        width="47px"
        fit="contain"
        :src="require('./../assets/images/login/icon_decoration1.png')"
      />
    </div>

    <!-- 分割线 -->
    <div class="divider_img">
      <van-image
        class="l_img"
        width="100%"
        height="44px"
        :src="require('./../assets/images/img_divider.png')"
      />
    </div>

    <div class="learn_more">
      <van-image
        class="l_img"
        width="100%"
        fit="contain"
        :src="require('./../assets/images/img_more.png')"
      />
      <div class="more_box">
        爱豆来电是中国移动联合彦成文化打造的一款功能性、娱乐性相结合的语音通知类产品。基于语音外呼能力和当下热门的明星资源，为个人用户提供明星语音定时通知服务。
      </div>
      <div class="more_box more_box1">
        爱豆来电可以根据您预先设置好的爱豆语音、提醒时间、提醒方式等，在到达预设时间时，为您推送明星语音提醒。
      </div>

      <div class="learn_more_btn">
        <van-image
          class="btn_img"
          width="90px"
          height="30px"
          @click="jumpPage(homeInfo.link_url)"
          :src="require('./../assets/images/img_more_btn.png')"
        />
      </div>

      <van-image
        class="icon_decoration2"
        width="38px"
        fit="contain"
        :src="require('./../assets/images/login/icon_decoration2.png')"
      />
      <van-image
        class="icon_decoration4"
        width="38px"
        fit="contain"
        :src="require('./../assets/images/login/icon_decoration4.png')"
      />
    </div>

    <!-- 分割线 -->
    <div class="divider_img pdt30">
      <van-image
        class="l_img"
        width="100%"
        height="44px"
        :src="require('./../assets/images/img_divider.png')"
      />
    </div>

    <div class="introduce">
      <div class="introduce_box"></div>
      <van-image
        class="l_img"
        width="80%"
        fit="contain"
        :src="require('./../assets/images/img_introduce.png')"
      />
      <div class="introduce_content">
        <div>你最想听爱豆对你说什么</div>
        <div>现在就告诉我们</div>
        <div>我们将综合选出若干条用户提交的内容</div>
        <div>邀请爱豆本人亲自录制</div>
        <div>把最完美的爱豆原声呈现给你</div>
      </div>

      <van-image
        class="icon_decoration3"
        width="29px"
        fit="contain"
        :src="require('./../assets/images/login/icon_decoration3.png')"
      />
    </div>

    <div class="fit_box"></div>

    <div class="footer" v-if="!isLogin || homeInfo.info_number == 0">
      <van-button class="footer_btn" color="#4BFFF2" round @click="goProcess"
        >我想听爱豆说的话</van-button
      >
    </div>
    <div class="footer" v-else>
      <van-button class="footer_btn btn1" color="#111111" round @click="toPage"
        >我的提交
        <div class="badge">{{ homeInfo.info_number }}</div>
      </van-button>
      <van-button
        class="footer_btn btn2 mgl10"
        color="#4BFFF2"
        round
        @click="goProcess"
        >我想听爱豆说的话</van-button
      >
    </div>

    <van-share-sheet
      v-model="showShare"
      title="立即分享给好友"
      :options="shareOptions"
      @select="onSelect"
    />

    <login-vue
      :isShow.sync="isShowLogin"
      @changeStatus="handleChange"
    ></login-vue>
  </div>
</template>

<script>
import Barrage from "../components/Barrage.vue";
import iNavBar from "../components/iNavBar.vue";
import LoginVue from "./popup/Login.vue";
import { hiddenPhone } from "@/utils/utils";
import { queryActive } from "@/request/api";

export default {
  name: "Home",
  data() {
    return {
      hiddenPhone,
      title: "最爱听爱豆说的话",
      isLogin: false,
      isShowBarrage: true,
      barrageIsShow: true,
      currentId: 0,
      phoneNum: "",
      barrageLoop: false,
      barrageList: [],
      isShowLogin: false,
      homeInfo: {
        link_url: "",
        info_number: 0,
      },
      isOpenShare: false,
      showShare: false,
      shareOptions: [
        { name: "微信", icon: "wechat" },
        { name: "微博", icon: "weibo" },
        { name: "复制链接", icon: "link" },
        { name: "分享海报", icon: "poster" },
        { name: "二维码", icon: "qrcode" },
      ],
    };
  },
  // 首页
  components: {
    iNavBar,
    Barrage,
    LoginVue,
  },
  watch: {
    "$route.query": {
      handler(v) {
        this.isShowLogin = v?.currentStatus;
      },
    },
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // 首页展现
      LA.track('mainpage_show');
      _hmt.push(["_trackEvent", "mainpage_show", "show"]);
      let token = localStorage.getItem("token") || "";
      this.phoneNum = localStorage.getItem("phone") || "";
      this.isOpenShare = localStorage.getItem("isOpenShare");
      // this.getBarrageMsg();
      this.getHomeInfo();
      this.isLogin = token.length > 0;
    },
    // 获取首页基本信息
    async getHomeInfo() {
      let data = await queryActive();
      console.log("返回的数据", data);
      this.homeInfo = data.details;
    },

    // // 获取弹幕信息
    // async getBarrageMsg() {
    //   let data = await bulletChat();
    //   if (data.rs_code == 1000) {
    //     this.barrageList = data.details;
    //   }
    // },
    //  打开弹幕
    openBarrage() {
      if (this.isShowBarrage) {
        LA.track('barrageoff_click');
        _hmt.push(["_trackEvent", "barrageoff_click", "click"]);
      }
      this.isShowBarrage = !this.isShowBarrage;
    },
    // 更改登录状态
    handleChange(val) {
      this.isLogin = val;
      this.phoneNum = localStorage.getItem("phone") || "";
      this.init();
    },
    // 跳转页面
    jumpPage(url) {
      console.log("页面地址", url);
      window.location.href = url;
    },

    // 进入流程
    goProcess() {
      LA.track('signinbtn_click');
      _hmt.push(["_trackEvent", "signinbtn_click", "click"]);
      if (!this.isLogin) {
        this.isShowLogin = true;
        return;
      }
      this.$router.push({
        path: "/chooseIdo",
      });
    },

    // 立即登录
    onLogin() {
      LA.track('loginbtn_click');
      LA.track('loginwin_show');
      _hmt.push(["_trackEvent", "loginbtn_click", "click"]);
      _hmt.push(["_trackEvent", "loginwin_show", "show"]);
      this.isShowLogin = true;
      console.log("立即登录");
    },
    // 退出
    onExit() {
      LA.track('logoutbtn_click');
      _hmt.push(["_trackEvent", "logoutbtn_click", "click"]);
      this.$store.commit("removeToken");
      this.$store.commit("removePhoneNum");
      this.isLogin = false;
      this.$toast("退出成功");
    },

    toPage() {
      LA.track('myuploadbtn_click');
      _hmt.push(["_trackEvent", "myuploadbtn_click", "click"]);
      this.$router.push({
        path: "/mySubmitList",
      });
    },

    // 去分享
    toShare() {
      this.showShare = true;
    },

    onSelect(option) {
      this.$toast(option.name);
      this.showShare = false;
    },
  },
};
</script>

<style scoped lang="less">
.van-image {
  display: block;
}
.home {
  background: #092770;
  width: 100%;
  .header_title {
    position: relative;
    height: 43px;
    background: linear-gradient(160deg, #5257cf 0%, #4bfff2 100%);
    display: flex;
    padding: 0 15px;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #4bfff2;
    // z-index: 10;
    // line-height: 20px;
    .header_title_l {
      position: absolute;
      left: 0;
      bottom: 0;
    }
    .header_title_r {
      display: flex;
      align-items: center;
      .userInfo {
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #5257cf;
        line-height: 25px;
      }
      .user_phone {
        margin-right: 5px;
        color: #ffffff;
      }
    }
  }

  .header {
    width: 100%;
    position: relative;
    .l_img {
      position: absolute;
      object-fit: contain;
      z-index: 1;
    }
  }

  .idol_main {
    position: relative;
    padding-top: 30px;
    background: linear-gradient(180deg, #4bfff2 0%, #5257cf 100%);
    .idol_box {
      position: relative;
      .l_img {
        z-index: 1;
      }
    }
    .barrage {
      .open_barrage {
        position: absolute;
        width: 230px;
        height: 50px;
        background: url("./../assets/images/back_open.png") no-repeat 100% 100%;
        background-size: contain;
        right: 0;
        bottom: -20px;
        left: 0;
        margin: auto;
      }
    }

    .open_barrage_btn {
      position: absolute;
      width: 170px;
      height: 26px;
      right: 0;
      left: 0;
      bottom: -30px;
      margin: auto;
      background: #111111;
      border: 1px solid #4bfff2;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #4bfff2;
    }
  }

  .barrage_machine {
    margin-top: -1px;
    box-sizing: border-box;
    // padding-top: 30px;
    padding-bottom: 50px;
    background: #5257cf;
    position: relative;
    .l_img {
      z-index: 1;
    }
    .barrage {
      .open_barrage {
        position: absolute;
        width: 230px;
        height: 50px;
        background: url("./../assets/images/back_open.png") no-repeat 100% 100%;
        background-size: contain;
        right: 0;
        bottom: 26px;
        left: 0;
        margin: auto;
      }
    }

    .close_barrage_btn {
      position: absolute;
      width: 170px;
      height: 26px;
      right: 0;
      left: 0;
      bottom: 16px;
      margin: auto;
      background: #6f87ff;
      border: 1px solid #4bfff2;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #4bfff2;
    }
    .barrage_content_box {
      // background-color: yellow;
      position: absolute;
      width: 307px;
      height: 260px;
      top: -78px;
      left: 34px;
      z-index: 2;
    }
  }

  .divider_img {
    margin-top: -1px;
    background: #5257cf;
    margin-bottom: -1px;
  }

  .learn_more {
    position: relative;
    padding: 40px 15px;
    background: #5257cf;
    .l_img {
      min-height: 212px;
    }
    .more_box {
      position: absolute;
      width: 290px;
      height: 80px;
      top: 69px;
      left: 0;
      right: 0;
      margin: auto;
      font-size: 14px;
      text-align: justify;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ffffff;
      line-height: 20px;
      text-shadow: 0px 2px 4px rgba(0, 1, 69, 0.2);
    }
    .more_box1 {
      bottom: -38px;
    }
    .btn_img {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 30px;
      margin: auto;
    }
  }

  .introduce {
    margin-bottom: -1px;
    position: relative;
    height: 280px;
    width: 100%;
    background: #5257cf;
    .l_img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    .introduce_box {
      position: absolute;
      top: -2px;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 190px;
      // background-color: yellow;
      background: url("./../assets/images/back_introduce.png") no-repeat 100%
        100%;
      background-size: contain;
    }
    .introduce_content {
      position: absolute;
      left: 0;
      right: 0;
      top: 10px;
      bottom: 0;
      margin: auto;
      text-align: center;
      width: 96%;
      height: 140px;
      object-fit: contain;
      // background-color: yellow;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #4bfff2;
      line-height: 27px;
      text-shadow: 0px 2px 3px rgba(0, 1, 69, 0.2);
    }
  }

  .fit_box {
    height: 50px;
    background: #5257cf;
  }

  .footer {
    box-sizing: border-box;
    border-top: 0.5px solid #484dc6;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    width: 100%;
    padding: 10px 20px;
    background-color: #5257cf;
    z-index: 2;

    .footer_btn {
      width: 100%;
      height: 37px;

      .van-button__text {
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #111111;
        line-height: 20px;
      }
    }
    .btn1 {
      position: relative;
      width: 120px;
      background: #111111;
      .badge {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: -10px;
        top: -10px;
        width: 24px;
        height: 24px;
        background: #4bfff2;
        border-radius: 50%;
        font-size: 12px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #111111;
        line-height: 16px;
      }
      .van-button__text {
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #4bfff2;
        line-height: 20px;
      }
    }
  }

  .icon_decoration1 {
    position: absolute;
    bottom: -14px;
  }
  .icon_decoration2 {
    position: absolute;
    right: 0;
    top: 0;
  }
  .icon_decoration3 {
    position: absolute;
    right: 37px;
  }
  .icon_decoration4 {
    position: absolute;
    left: 18px;
    bottom: 0;
  }
  .icon_decoration5 {
    position: absolute;
    right: 0;
    bottom: 10px;
  }
}
</style>