import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 插件
import "./plugins/vant";
import "./plugins/baberrage";
// 给html设置font-size值,暂时不用
import "amfe-flexible";
import "./styles/global.less";

Vue.config.productionTip = false;
router.afterEach(() => {
  window.scrollTo(0, 0);
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
