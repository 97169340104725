import { current } from "./base";

// 登录
export const login = (data) => {
  return current({
    url: "/Idol/Listen/login",
    method: "post",
    data,
  });
};

// 页面信息初始化
export const queryActive = (data) => {
  return current({
    url: "/Idol/Listen/queryActive",
    method: "get",
    data,
  });
};
// 发送验证码
export const sendCode = (data) => {
  return current({
    url: "/Idol/Listen/sendCode",
    method: "post",
    data,
  });
};

// 发送图形验证码
export const getImgCode = (data) => {
  return current({
    url: "/Idol/Listen/getImgCode",
    method: "post",
    data,
  });
};

// 获取所有爱豆信息
export const getStarData = (data) => {
  return current({
    url: "/Idol/Listen/getStarData",
    method: "post",
    data,
  });
};
// 获取所有场景信息
export const getSceneData = (data) => {
  return current({
    url: "/Idol/Listen/getSceneData",
    method: "post",
    data,
  });
};
// 我提交的信息
export const myInfo = (data) => {
  return current({
    url: "/Idol/Listen/myInfo",
    method: "post",
    data,
  });
};
// 提交信息
export const sendInfo = (data) => {
  return current({
    url: "/Idol/Listen/sendInfo",
    method: "post",
    data,
  });
};
// 点赞
export const thumbsUp = (data) => {
  return current({
    url: "/Idol/Listen/thumbsUp",
    method: "post",
    data,
  });
};
// 信息详情
export const infoDetail = (data) => {
  return current({
    url: "/Idol/Listen/infoDetail",
    method: "post",
    data,
  });
};
// 弹幕机
export const bulletChat = (data) => {
  return current({
    url: "/Idol/Listen/bulletChat",
    method: "post",
    data,
  });
};
