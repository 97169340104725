<template>
  <div class="navbar_head">
    <van-nav-bar
      :title="iTitle"
      fixed
      safe-area-inset-top
      z-index="3"
      :left-arrow="isShowLeftArrow"
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <template #title>
        <span class="iNavBar_tit">{{ iTitle }}</span>
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
export default {
  name: "INarBar",
  props: {
    iTitle: {
      type: String,
      default: "",
    },
    isShowLeftArrow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {},
  },
};
</script>

<style lang="less" scoped>
.iNavBar_tit {
  height: 25px;
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #1c1e3d;
  line-height: 25px;
}
</style>