import Vue from "vue";

import {
  Button,
  NavBar,
  List,
  Cell,
  CellGroup,
  Sticky,
  Lazyload,
  Icon,
  Grid,
  GridItem,
  Tag,
  Popover,
  Popup,
  Overlay,
  Image as VanImage,
  Field,
  Uploader,
  DatetimePicker,
  Picker,
  Empty,
  Calendar,
  Dialog,
  Form,
  Loading,
  ShareSheet,
} from "vant";
[
  Button,
  NavBar,
  List,
  Cell,
  CellGroup,
  Sticky,
  Icon,
  Grid,
  GridItem,
  Tag,
  Popover,
  Popup,
  Overlay,
  VanImage,
  Field,
  Uploader,
  DatetimePicker,
  Picker,
  Empty,
  Calendar,
  Dialog,
  Form,
  Loading,
  ShareSheet,
].forEach((item) => Vue.use(item));

Vue.use(Lazyload, {
  loading:
    "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F0148f05a8ad95ca8012045b3719b27.gif&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1633343336&t=0b09a4cf812e9b41624f6adc59e62414",
});
