<template>
  <div class="barrages-drop">
    <vue-baberrage
      :isShow="barrageIsShow"
      :barrageList="barrageList"
      :boxHeight="boxHeight"
      :messageHeight="messageHeight"
      :maxWordCount="maxWordCount"
      :loop="barrageLoop"
      :throttleGap="throttleGap"
      :lanesCount="lanesCount"
      :hoverLanePause="hoverLanePause"
      ref="baberrage"
    >
      <template v-slot:default="slotProps">
        <div class="barrage_item">
          <span class="item_l">{{ slotProps.item.msg }}</span>
          <span class="item_r" @click="toViewDetail(slotProps)">查看</span>
        </div>
      </template>
    </vue-baberrage>
    <barrage-vue
      :is-show-barrage.sync="isShowBarrage"
      :lId="currentId"
    ></barrage-vue>
  </div>
</template>
<script>
import Vue from "vue";
import { vueBaberrage, MESSAGE_TYPE } from "vue-baberrage";
import { getRandomNum } from "@/utils/utils";
import { bulletChat } from "@/request/api";
import BarrageVue from "../views/popup/Barrage.vue";
Vue.use(vueBaberrage);
export default {
  name: "Barrages",
  components: { BarrageVue },
  data() {
    return {
      getRandomNum,
      /**
       * params
       * isShow        是否显示弹幕
       * barrageList   弹幕数据列表
       * boxWidth      显示弹幕区域的宽度
       * boxHeight     显示弹幕区域的高度
       * messageHeight 弹幕高度
       * maxWordCount  弹幕最大字数长度，超过则忽略（中文占2长度，英文占1长度）
       * loop          是否循环
       * throttleGap   弹幕之间的节流时间
       * posRender     自定义弹幕显示的泳道
       * lanesCount    泳道的数量。
       */
      currentId: 0,
      barList: [],
      isShowBarrage: false,
      hoverLanePause: true,
    };
  },
  props: {
    boxHeight: {
      type: Number,
      default: 180,
    },
    lanesCount: {
      type: Number,
      default: 4,
    },
    messageHeight: {
      type: Number,
      default: 44,
    },
    barrageList: {
      type: Array,
      default: () => [],
    },
    throttleGap: {
      type: Number,
      default: 8000,
    },
    barrageLoop: {
      type: Boolean,
      default: true,
    },
    barrageIsShow: {
      type: Boolean,
      default: true,
    },
    maxWordCount: {
      type: Number,
      default: 6,
    },
  },

  watch: {
    barrageList: {
      handler(v) {
        this.barrageList = v || [];
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.getBarrageMsg();
  },
  methods: {
    // 暂停弹幕
    pauseBaberrage() {
      this.$refs.baberrage.pause();
    },

    // 获取弹幕信息
    async getBarrageMsg() {
      let data = await bulletChat();
      console.log("弹幕机返回的数据", data);
      if (data.rs_code == 1000) {
        this.barList = data.details;
        this.addToList(this.barList);
      }
    },
    addToList(arr) {
      arr.forEach((v) => {
        this.barrageList.push({
          id: v.info_id,
          msg: v.info,
          time: getRandomNum(3, 6),
          extraWidth: 210,
          type: MESSAGE_TYPE.NORMAL,
        });
      });
    },
    toViewDetail(item) {
      this.isShowBarrage = true;
      this.currentId = item.item.id;
      // this.pauseBaberrage();
      console.log("查看信息", item.item.id);
    },
  },
};
</script>
<style lang="less">
.barrages-drop {
  .blue {
    border-radius: 100px;
    background: #e6ff75;
    color: #fff;
  }
  .green {
    border-radius: 100px;
    background: #75ffcd;
    color: #fff;
  }
  .red {
    border-radius: 100px;
    background: #e68fba;
    color: #fff;
  }
  .yellow {
    border-radius: 100px;
    background: #dfc795;
    color: #fff;
  }
  .barrage_item {
    // min-width: 210px;
    height: 28px;
    background: #4d508f;
    border-radius: 14px;
    display: flex;
    align-items: center;
    .item_l {
      flex: 1;
      padding: 0 15px;
      text-align: center;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #4bfff2;
    }
    .item_r {
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #111111;
      width: 30px;
      height: 28px;
      padding-left: 10px;
      padding-right: 10px;
      line-height: 28px;
      background: #4bfff2;
      border-radius: 0px 100px 100px 0px;
    }
  }

  .baberrage_msg {
    width: 149px;
    height: 28px;
    background: #4d508f;
    border-radius: 14px;
    font-size: 14px;
  }
  .baberrage-stage {
    position: absolute;
    width: 100%;
    height: 212px;
    overflow: hidden;
    top: 0;
    margin-top: 130px;
  }
}
</style>