<template>
  <div id="app">
    <i-nav-bar :iTitle="$route.meta.name" v-if="!$route.meta.isShowNav"></i-nav-bar>
    <router-view class="view_main" />
  </div>
</template>
<script>
import iNavBar from "@/components/iNavBar.vue";
export default {
  name: "App",
  data() {
    return {
      title: "选择爱豆",
    };
  },
  mounted() {
    console.log("路由信息", this.$route.meta);
  },
  components: {
    iNavBar,
  },
};
</script>


<style lang="less" scoped>
#app {
  background: #f3f3f3;
  .view_main {
    padding-top: 46px;
  }
}
</style>
