import axios from "axios";
import { Toast } from "vant";
import router from "../router";

axios.defaults.withCredentials = true;
// 单例（实例）
const req = ({ baseURL, headers }) => {
  const instance = axios.create({
    baseURL,
    headers,
    timeout: 100000,
  });

  // 请求拦截器
  instance.interceptors.request.use(
    (config) => {
      // 在发送请求之前做些什么
      // 处理 headers 中token问题
      const headers = config.headers;
      const token = localStorage.getItem("token");
      config.headers = {
        Authorization: "Bearer " + token,
        token,
        ...headers,
      };

      return config;
    },
    (err) => {
      // 请求发生错误时
      Toast(`请求时的错误`);
      return Promise.reject(err);
    }
  );
  instance.defaults.withCredentials = true;
  // 响应拦截器
  instance.interceptors.response.use(
    (res) => {
      console.log("响应状态吗", res);
      if (res.data.rs_code == 2001) {
        Toast(res.data.rs_msg);
      } else if (res.data.rs_code == 2002) {
        Toast(res.data.rs_msg);
        this.$store.commit("removeToken");
        this.$store.commit("removePhoneNum");
        window.localStorage.clear();
        router.push("/home");
      }
      return res.data;
    },
    (err) => {
      Toast(`响应时的错误`);
      return Promise.reject(err);
    }
  );
  return instance;
};

export default req;
