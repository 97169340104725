import req from "./http";

export const current = req({
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: "/Ido",
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    timestamps: new Date().getTime(),
  },
});
