<template>
  <div>
    <van-dialog v-model="isShowBarrage" :showConfirmButton="false" width="78%">
      <div class="barrage_pop">
        <!-- <van-image
          class="l_img"
          width="30px"
          height="30px"
          :src="require('./../../assets/images/icon_close_b.png')"
        /> -->
        <div class="pop_tit">
          {{ detailInfo.pub_mobile }}想听
          <span class="pop_name">{{ detailInfo.star_name }}</span> 说：
        </div>
        <div class="pop_content">
          {{ detailInfo.con }}
        </div>
        <div class="pop_footer">
          <div class="footer_l">{{ detailInfo.scene_name }}</div>
          <div class="footer_r">
            <van-image
              v-if="!isGive"
              class="l_img"
              width="20px"
              height="20px"
              @click="toGive(detailInfo.info_id)"
              :src="require('./../../assets/images/icon_cancel_give.png')"
            />
            <van-image
              v-else
              class="l_img"
              width="20px"
              height="20px"
              :src="require('./../../assets/images/icon_give.png')"
            />
            <span class="pop_num">{{ detailInfo.yes_num }}</span>
          </div>
        </div>
        <img
          class="close_img"
          @click="onCancel"
          src="./../../assets/images/icon_close_b.png"
          alt=""
        />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { infoDetail, thumbsUp } from "@/request/api";
export default {
  name: "Barrage",
  data() {
    return {
      isShow: false,
      isGive: false,
      detailInfo: {},
    };
  },
  props: {
    isShowBarrage: {
      type: Boolean,
      default: false,
    },
    lId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    lId: {
      handler(v) {
        console.log("传过来的值", v);
        this.getMsgDetail(v);
      },
    },
  },
  mounted() {},
  methods: {
    // 获取信息
    async getMsgDetail(id) {
      let params = {
        info_id: id,
      };
      let data = await infoDetail(params);
      console.log("信息详情返回的数据", data);
      if (data.rs_code == 1000) {
        this.detailInfo = data.details || {};
        this.isGive = data.details.is_yes || false;
      }
    },

    async toGive(id) {
      let params = {
        info_id: id,
      };
      let data = await thumbsUp(params);
      if (data.rs_code == 1000) {
        this.isGive = true;
        this.getMsgDetail(id);
      } else {
        this.isGive = false;
      }
    },
    onCancel() {
      this.$emit("update:isShowBarrage", false);
    },
  },
};
</script>


<style lang="less" scoped>
.dialog_box {
  background-color: transparent;
}
/deep/ .van-dialog {
  overflow: inherit;
}
.barrage_pop {
  position: relative;
  box-sizing: border-box;
  // width: 280px;
  min-height: 260px;
  padding: 20px 25px;
  text-align: justify;
  background: #5257cf;
  border: 1px solid #4bfff2;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #ffffff;
  z-index: 100;
  .pop_tit {
    font-weight: 700;
    line-height: 20px;
    .pop_name {
      color: #4bfff2;
    }
  }
  .pop_content {
    min-height: 140px;
    margin-top: 15px;
  }
  .pop_footer {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer_l {
      height: 28px;
      background: #4d508f;
      color: #4bfff2;
      border-radius: 14px;
      text-align: center;
      box-sizing: border-box;
      padding: 4px 10px;
    }
    .footer_r {
      display: flex;
      //   align-items: center;
    }
    .pop_num {
      margin-left: 5px;
      color: #4bfff2;
      line-height: 20px;
    }
  }
  .close_img {
    position: absolute;
    left: 0;
    right: 0;
    width: 30px;
    height: 30px;
    bottom: -30%;
    margin: auto;
  }
}
</style>