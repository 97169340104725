import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    phoneNum: "",
  },
  mutations: {
    addPhoneNum(state, str) {
      localStorage.setItem("phone", str);
      state.phoneNum = str;
    },
    removePhoneNum(state) {
      localStorage.removeItem("phone");
      state.phoneNum = undefined;
    },
    addToken(state, token) {
      localStorage.setItem("token", token);
      state.token = token;
    },
    removeToken(state) {
      localStorage.removeItem("token");
      state.token = undefined;
    },
  },
  actions: {},
  modules: {},
});
