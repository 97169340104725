import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    // 重定向
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home", // 首页
    name: "Home",
    component: Home,
    meta: {
      name: "最想听爱豆说的话",
    },
  },
  {
    path: "/chooseIdo", // 选择爱豆
    name: "ChooseIdo",
    component: () => import("@/views/ido/index"),
    meta: {
      name: "选择爱豆",
    },
  },
  {
    path: "/scene", // 选择场景
    name: "Scene",
    component: () => import("@/views/scene/index"),
    meta: {
      name: "选择场景",
    },
  },
  {
    path: "/mySubmit", // 我的提交
    name: "MySubmit",
    component: () => import("@/views/mySubmit/index"),
    meta: {
      name: "我的提交",
    },
  },
  {
    path: "/mySubmitList", // 我的提交列表页
    name: "MySubmitList",
    component: () => import("@/views/mySubmit/List"),
    meta: {
      name: "我的提交",
    },
  },
  {
    path: "/success", // 提交成功
    name: "Success",
    component: () => import("@/views/mySubmit/Success"),
    meta: {
      isShowNav: true,
    },
  },
  {
    path: "/voice", // 语音内容
    name: "Voice",
    component: () => import("@/views/voice/index"),
    meta: {
      name: "语音内容",
    },
  },
  {
    path: "/noData", // 暂无数据
    name: "NoData",
    component: () => import("../views/404/NoData.vue"),
    meta: {
      keepAlive: true,
    },
  },
  {
    path: "*",
    name: "notfound",
    component: () => import("../views/404"),
  },
];

const router = new VueRouter({
  // mode: "history",
  // base: window.__POWERED_BY_QIANKUN__ ? "/idolsaid/" : "/",
  routes,
});

router.beforeEach((to, from, next) => {
  let routerNameArr = ["Home", "NoData", "notfound"];
  let bol = routerNameArr.some((item) => item == to.name);
  if (bol) {
    next();
  } else {
    if (window.localStorage.getItem("token")) {
      next();
    } else {
      router.push({
        name: "Home",
        query: {
          currentStatus: true,
        },
      });
    }
  }
});

export default router;
