// 给文本加省略号
/**
 *
 * @param {字符串} str
 * @param {*长度} len
 * @returns
 */
let fontText = (str, len) => {
  const length = str.length;
  if (length > len) {
    let newStr = "";
    newStr = str.substring(0, len) + "...";
    return newStr;
  } else {
    return str;
  }
};
// 隐藏手机号
let hiddenPhone = (str = "", replaceStr = "****") => {
  if (str.length == 0) {
    return str;
  }
  let reg = /(\d{3})\d{4}(\d{4})/;
  let tel = str.replace(reg, `$1${replaceStr}$2`);
  return tel;
};

// 获取随机数
let getRandomNum = (min, max) => {
  let num = Math.random() * (max - min) + min;
  return num;
};

// 判断图片是否有效
let judgeImgEffective = (imgurl) => {
  let ImgObj = new Image();
  ImgObj.src = imgurl;
  return ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0);
};

export { fontText, hiddenPhone, getRandomNum, judgeImgEffective };
