<template>
  <div>
    <van-dialog v-model="isShow" :showConfirmButton="false" width="78%">
      <div class="login_pop">
        <div class="login_txt">请您登录</div>
        <div class="login_box">
          <div class="ipt_box">
            <input
              type="text"
              v-model="form.mobile"
              maxlength="20"
              class="ipt"
              placeholder="请输入手机号码"
            />
            <img
              class="ipt_img"
              src="./../../assets/images/login/icon_phone.png"
              alt=""
            />
          </div>

          <div class="ipt_box imgCodeBox mgt15">
            <input
              type="text"
              v-model="form.img_code"
              maxlength="10"
              class="ipt imgCode"
              placeholder="请输入验证码"
            />
            <img
              class="ipt_img code_img"
              src="./../../assets/images/login/icon_img_code.png"
              alt=""
            />
            <div @click="getImgCode" class="img_code_box mgl10">
              <img-code-vue
                class=""
                :contentHeight="34"
                :contentWidth="80"
                :identifyCode="identifyCode"
              ></img-code-vue>
            </div>
          </div>
          <div class="ipt_box">
            <input
              type="text"
              v-model="form.sms_code"
              maxlength="20"
              class="ipt ipt_sms mgt15"
              placeholder="请输入验证码"
            />
            <img
              class="ipt_img"
              src="./../../assets/images/login/icon_sms.png"
              alt=""
            />
            <span v-show="show" class="code_txt" @click="getCode">{{
              code_txt
            }}</span>
            <span v-show="!show" class="code_txt">{{ count }} s</span>
          </div>
          <van-button class="login_btn" @click="onLogin" color="#4BFFF2" round
            >登录</van-button
          >
        </div>
        <img
          class="close_img"
          @click="onCancel"
          src="./../../assets/images/icon_close_b.png"
          alt=""
        />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { login, sendCode, getImgCode } from "@/request/api";
import ImgCodeVue from "../../components/ImgCode.vue";
export default {
  name: "Login",
  components: {
    ImgCodeVue,
  },
  data() {
    return {
      show: true,
      code_txt: "获取验证码",
      count: "",
      identifyCode: "QWER",
      terminal: "",
      timer: null,
      form: {
        mobile: "",
        sms_code: "",
        img_code: "",
      },
    };
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isShow: {
      handler(v) {
        console.log("传过来的值", this.$options.data.call());
        if (v) {
          let arr = ["code_txt", "count", "timer", "show", "form", "terminal"];
          arr.map((item) => {
            // this[item] = this.$options.data.call()[item];
            this.$set(this, item, this.$options.data.call()[item]);
          });
          this.getImgCode();
          console.log("this", this);
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    onCancel() {
      this.$emit("update:isShow", false);
      clearInterval(this.timer);
    },
    // 登录
    async onLogin() {
      LA.track('loginsuccess_click');
      _hmt.push(["_trackEvent", "loginsuccess_click", "click"]);
      if (
        this.form.mobile == "" ||
        this.form.sms_code == "" ||
        this.form.img_code == ""
      ) {
        this.$toast("请填写信息");
        return;
      }
      let params = {
        ...this.form,
      };
      let data = await login(params);
      console.log("登录返回的数据", data);
      if (data.rs_code == 1000) {
        this.$store.commit("addToken", data.details.token);
        this.$store.commit("addPhoneNum", this.form.mobile);
        this.$toast("登录成功");
        this.$emit("changeStatus", true);
        this.onCancel();
      }
    },
    // 获取短信验证码
    async getSmsCode() {
      let params = {
        mobile: this.form.mobile,
        img_code: this.form.img_code,
        terminal: this.terminal,
      };
      let data = await sendCode(params);
      console.log("返回的数据", data);
      if (data.rs_code === 1000) {
        this.$toast("获取成功");
      }
    },
    // 获取图片验证码
    async getImgCode() {
      let data = await getImgCode();
      console.log("图片验证码返回的数据", data);
      if (data.rs_code === 1000) {
        this.identifyCode = data.details?.code || "";
        this.terminal = data.details?.terminal || "";
      }
    },

    // 获取短信验证码
    getCode() {
      if (this.form.mobile.length == 0) {
        this.$toast("手机号不能为空");
        return false;
      }
      if (
        this.form.img_code.toLowerCase() !== this.identifyCode.toLowerCase()
      ) {
        this.$toast("验证码错误");
        return false;
      }
      this.getSmsCode();
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
  },
};
</script>


<style lang="less" scoped>
.dialog_box {
  background-color: transparent;
}
/deep/ .van-dialog {
  overflow: inherit;
  border-radius: 0 !important;
}
.login_pop {
  position: relative;
  box-sizing: border-box;
  // width: 280px;
  // height: 260px;
  padding: 20px 25px;
  background: #5257cf;
  border: 1px solid #4bfff2;
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #ffffff;
  .login_txt {
    text-align: center;
    margin-top: 5px;
    font-size: 17px;
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #4bfff2;
    line-height: 23px;
  }
  .login_box {
    margin-top: 25px;
    .ipt_box {
      position: relative;
      .code_txt {
        position: absolute;
        font-size: 14px;
        font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
        font-weight: normal;
        color: #4bfff2;
        line-height: 20px;
        right: 10px;
        bottom: 8px;
      }
    }

    .imgCodeBox {
      // width: 80px;
      display: flex;
      justify-content: space-between;
    }
    .ipt {
      box-sizing: border-box;
      width: 100%;
      background: #5257cf;
      border: 1px solid #6f87ff;
      height: 36px;
      padding-left: 40px;
      line-height: 36px;
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ffffff;
    }
    .imgCode {
      width: 100%;
    }
    .ipt_sms {
      padding-right: 100px;
    }
    .ipt_img {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 10px;
      bottom: 8px;
    }
    .code_img {
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .login_btn {
    width: 100%;
    margin-top: 30px;
    height: 36px;
    background: #4bfff2;
    border-radius: 18px;
    /deep/ .van-button__content {
      font-size: 14px;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #111111;
    }
  }
  .close_img {
    position: absolute;
    left: 0;
    right: 0;
    width: 30px;
    height: 30px;
    bottom: -60px;
    margin: auto;
  }
}

input::-webkit-input-placeholder {
  margin-top: 1px;
  /* WebKit browsers */
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #6f87ff;
}
input:-moz-placeholder {
  margin-top: 1px;
  /* Mozilla Firefox 4 to 18 */
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #6f87ff;
}
input::-moz-placeholder {
  margin-top: 1px;
  /* Mozilla Firefox 19+ */
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #6f87ff;
}
input:-ms-input-placeholder {
  margin-top: 1px;
  /* Internet Explorer 10+ */
  font-size: 14px;
  font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
  font-weight: normal;
  color: #6f87ff;
}
input[type="text"] {
  padding-top: 4px;
  -webkit-appearance: none;
}
textarea {
  -webkit-appearance: none;
}
</style>